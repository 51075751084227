import {Button, Input, Modal, Select} from '../../../components';
import {strings} from '../../../services/language';
import styles from './General.module.scss';
import {Centre} from '../../../services/models';
import {locationOptions} from '../../../services/helpers';
import {useEffect, useRef, useState} from 'react';
import {
  API,
  createCentreAPI,
  getCentreByIdAPI,
  updateCentreAPI,
  useAPI,
} from '../../../services/api';
import {useLoading} from '../../../services/hooks';
import {useHistory} from 'react-router-dom';

type Props = {
  centre?: Centre;
  isNew?: boolean;
  centreId?: string;
};

export default function General({centre, isNew, centreId}: Props) {
  const history = useHistory();
  const [centreProfile, setCentreProfile] = useState<Centre | undefined>(
    centre
  );
  const resultModalRef = useRef<any>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const getCentreById = useAPI(getCentreByIdAPI);

  useEffect(() => {
    if (centreId && centreId !== 'new')
      getCentreById.request({id: centreId || ''});
  }, [centreId]);

  useEffect(() => {
    if (getCentreById.data) {
      setCentreProfile(getCentreById.data);
    }
  }, [getCentreById.data]);

  useEffect(() => {
    if (isNew) {
      setCentreProfile({
        id: 0,
        totalReviews: 0,
        districtGroup: '',
        address: '',
        districtNumber: 0,
        createdAt: '',
        updatedAt: '',
        name: '',
        bsc: '',
        phone: '',
      });
    } else if (centre) {
      setCentreProfile(centre);
    }
  }, [isNew, centre]);

  const submitForm = useAPI(() => {
    if (isNew && centreProfile) {
      return createCentreAPI({centre: centreProfile});
    }
    if (centreProfile) return updateCentreAPI({centre: centreProfile});
    return {} as API<unknown>;
  });

  const isInvalid =
    !centreProfile?.address ||
    !centreProfile?.name ||
    !centreProfile?.districtNumber ||
    !centreProfile?.phone ||
    !centreProfile?.bsc ||
    !/^\d+$/.test(centreProfile?.phone || '') ||
    /[^a-zA-Z0-9]/.test(centreProfile?.bsc || '');

  const handleSubmitData = () => {
    resultModalRef.current.hideModal();
    setIsSubmitted(true);
    if (!isInvalid) submitForm.request();
  };

  useLoading(submitForm.loading);

  useEffect(() => {
    if ((submitForm.data?.success || submitForm.error) && !submitForm.loading) {
      resultModalRef.current.showModal();
    }
  }, [submitForm.loading, submitForm.error]);

  const isChanged =
    centre?.address !== centreProfile?.address ||
    centre?.bsc !== centreProfile?.bsc ||
    centre?.districtNumber !== centreProfile?.districtNumber ||
    centre?.name !== centreProfile?.name ||
    centre?.phone !== centreProfile?.phone;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.inputItem}>
          <Input
            id="bsc"
            value={centreProfile?.bsc || ''}
            type="text"
            disabled={!isNew}
            label={'BSC*'}
            handleChange={e => {
              setCentreProfile(
                s =>
                  ({
                    ...s,
                    bsc: e.target.value,
                  } as unknown as Centre)
              );
            }}
            error={
              isSubmitted && !centreProfile?.bsc
                ? 'Required'
                : isSubmitted && /[^a-zA-Z0-9]/.test(centreProfile?.bsc || '')
                ? 'Invalid: No special characters allowed'
                : ''
            }
          />
        </div>
        {!isNew && (
          <div className={styles.inputItem}>
            <Input
              id="reviewNo"
              disabled
              value={centreProfile?.totalReviews || '0'}
              type="text"
              handleChange={e => {
                setCentreProfile(
                  s =>
                    ({...s, totalReviews: e.target.value} as unknown as Centre)
                );
              }}
              label={strings('No. of Reviews')}
            />
          </div>
        )}
        <div className={styles.inputItem}>
          <Input
            id="Name"
            value={centreProfile?.name || ''}
            type="text"
            handleChange={e => {
              setCentreProfile(
                s => ({...s, name: e.target.value} as unknown as Centre)
              );
            }}
            error={isSubmitted && !centreProfile?.name ? 'Required' : ''}
            label={'Name*'}
          />
        </div>
        <div className={styles.inputItem}>
          <Select
            border
            isFullWidth
            topLabel={'General Location*'}
            defaultLabel={'General Location*'}
            optionList={locationOptions}
            value={centreProfile?.districtNumber || 0}
            searchable
            onSelectValue={values => {
              setCentreProfile(
                s => ({...s, districtNumber: values?.[0]} as unknown as Centre)
              );
            }}
            error={
              isSubmitted && !centreProfile?.districtNumber ? 'Required' : ''
            }
          />
        </div>
        <div className={styles.inputItem}>
          <Input
            id="location"
            value={centreProfile?.address || ''}
            type="text"
            label={'Address*'}
            handleChange={e => {
              setCentreProfile(
                s => ({...s, address: e.target.value} as unknown as Centre)
              );
            }}
            error={isSubmitted && !centreProfile?.address ? 'Required' : ''}
          />
        </div>
        <div className={styles.inputItem}>
          <Input
            id="phone"
            value={centreProfile?.phone || ''}
            type="text"
            label={'Phone Number*'}
            handleChange={e => {
              setCentreProfile(
                s => ({...s, phone: e.target.value} as unknown as Centre)
              );
            }}
            error={
              isSubmitted && !centreProfile?.phone
                ? 'Required'
                : isSubmitted && !/^\d+$/.test(centreProfile?.phone || '')
                ? 'Invalid'
                : ''
            }
          />
        </div>
        <div className={styles.inputItem}>
          <Input
            id="dgbsc"
            value={centreProfile?.postalCode || ''}
            type="text"
            label={'Postal Code'}
            handleChange={e => {
              setCentreProfile(
                s => ({...s, postalCode: e.target.value} as unknown as Centre)
              );
            }}
          />
        </div>
      </div>
      <div className={styles.button}>
        <Button
          type="danger"
          disabled={!isChanged}
          label={strings('Save Changes')}
          handleClick={handleSubmitData}
        />
      </div>

      <Modal
        ref={resultModalRef}
        body={
          <div>
            <div>
              {submitForm.error
                ? `${submitForm.error}`
                : submitForm.data?.success
                ? 'Save Changes Success'
                : ''}
            </div>
          </div>
        }
        footer={
          <>
            <Button
              label={strings('Done')}
              type="danger"
              handleClick={() => {
                resultModalRef.current.hideModal();
                if (!submitForm.error) {
                  if (isNew) history.push('/centres');
                  else history.go(0);
                }
              }}
            />
          </>
        }
        showClose={false}
      />
    </>
  );
}
