import styles from './TimesheetList.module.scss';
import {Table, Select, Button, PcfDatePicker, Modal} from '../../../components';
import {ReactComponent as SearchIcon} from '../../../assets/img/search.svg';
import {ReactComponent as WorkIcon} from '../../../assets/img/work.svg';
import {ReactComponent as TagIcon} from '../../../assets/img/tag.svg';
import {ReactComponent as LocationIcon} from '../../../assets/img/location.svg';
import {ReactComponent as CalenderIcon} from '../../../assets/img/calender.svg';
import {ReactComponent as CrossIcon} from '../../../assets/img/cross.svg';
import {strings} from '../../../services/language';
import {useTimesheetsLogic} from './logics';

export default function TimesheetList({
  payrollId,
}: {
  payrollId: number | undefined;
}) {
  const {
    _limit,
    columns,
    data,
    total,
    manualFilterSort,
    setStatus,
    setDistrict,
    setPayrollMonth,
    setSearchField,
    setStartDate,
    setEndDate,
    setRole,
    searchValue,
    setSearchValue,
    searchFilterList,
    payrollMonthOptions,
    timesheetStatusOptions,
    centres,
    district,
    skillOptions,
    fetchData,
    removePayrollId,
    exportTimesheets,
    resultModalRef,
    error,
  } = useTimesheetsLogic(payrollId);

  return (
    <div className={styles.container}>
      <div className={styles.filtersContainer}>
        <div className={styles.searchWrapper}>
          <div className={styles.searchInput}>
            <SearchIcon
              className={styles.searchIcon}
              style={{fill: 'var(--grey-text)'}}
            />
            <input
              type="text"
              name="timesheetQuery"
              id="timesheetQuery"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder={strings('Search Timesheets')}
            />
            <div className={styles.searchFilterWrapper}>
              <Select
                optionList={searchFilterList}
                allItem
                onSelectValue={values => {
                  values.length > 0
                    ? setSearchField(values[0])
                    : setSearchField('all');
                }}
              />
            </div>
          </div>
          <span className={styles.numberRecord}>{`${total} ${strings(
            'Records'
          )}`}</span>
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.selectFilters}>
            <PcfDatePicker
              placeholder={strings('Date')}
              sgTimeZone
              onSelectDate={value => {
                setStartDate(value.from || '');
                setEndDate(value.to || '');
              }}
            />
            <Select
              fixedLabel={
                <WorkIcon
                  style={{
                    stroke: 'var(--blue-accent)',
                    width: '16px',
                    height: '16px',
                  }}
                />
              }
              defaultLabel={strings('Roles')}
              optionList={skillOptions}
              multipleSelect
              allToggle
              allItem
              onSelectValue={values => setRole(values)}
            />
            <Select
              fixedLabel={<LocationIcon />}
              defaultLabel={'BSC'}
              optionList={centres.map(item => ({
                value: item.bsc,
                content: item.bsc,
              }))}
              value={district.map(item => item)}
              multipleSelect
              searchable
              allToggle
              allItem
              divider
              onSelectValue={values => setDistrict(values)}
            />
            <Select
              fixedLabel={<TagIcon />}
              defaultLabel={strings('Status')}
              optionList={timesheetStatusOptions}
              multipleSelect
              allToggle
              allItem
              onSelectValue={values => setStatus(values)}
            />
            <Select
              fixedLabel={
                <CalenderIcon
                  style={{
                    fill: 'var(--blue-accent)',
                  }}
                />
              }
              defaultLabel={strings('Payroll Month')}
              optionList={payrollMonthOptions}
              multipleSelect
              allToggle
              allItem
              divider
              onSelectValue={values => setPayrollMonth(values)}
            />
            {payrollId !== undefined && (
              <div className={styles.customFilter}>
                <div>
                  <b>{strings('Payroll ID')}: </b>
                  <span>{payrollId}</span>
                </div>
                <div className={styles.removeIcon} onClick={removePayrollId}>
                  <CrossIcon />
                </div>
              </div>
            )}
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              type="outline"
              label={strings('Export')}
              handleClick={exportTimesheets}
            />
          </div>
        </div>
      </div>
      <Modal
        ref={resultModalRef}
        showClose={false}
        body={
          <div>
            <div>{error}</div>
          </div>
        }
        footer={
          <>
            <Button
              label={strings('Ok')}
              type="danger"
              handleClick={() => {
                resultModalRef.current.hideModal();
              }}
            />
          </>
        }
      />
      <Table
        columns={columns}
        data={data}
        rowClick
        pageInput
        minWidth={1800}
        manualQuery={searchValue}
        manualFilterSort={manualFilterSort}
        pageCountNumber={Math.ceil(total / _limit)}
        fetchData={fetchData}
      />
    </div>
  );
}
