import {strings} from '../../../services/language';
import {Table, Select, Button, Modal} from '../../../components';
import styles from './PayrollRecords.module.scss';
import {ReactComponent as SearchIcon} from '../../../assets/img/search.svg';
import {ReactComponent as TagIcon} from '../../../assets/img/tag.svg';
import {ReactComponent as LocationIcon} from '../../../assets/img/location.svg';
import {ReactComponent as CalenderIcon} from '../../../assets/img/calender.svg';
import {usePayrollsLogic} from './logics';

export default function PayrollRecords() {
  const {
    _limit,
    columns,
    data,
    hiddenColumns,
    total,
    manualFilterSort,
    selectedRows,
    setSelectedRows,
    setStatus,
    setDistrict,
    setPayrollMonth,
    setSearchField,
    searchValue,
    setSearchValue,
    submitModalRef,
    searchFields,
    payrollMonthOptions,
    payrollStatusOptions,
    district,
    fetchData,
    submitPayrolls,
    resultModalRef,
    centres,
    exportPayrolls,
    error,
  } = usePayrollsLogic();

  return (
    <div className={styles.container}>
      <div className={styles.filtersContainer}>
        <div className={styles.searchWrapper}>
          <div className={styles.searchInput}>
            <SearchIcon
              className={styles.searchIcon}
              style={{fill: 'var(--grey-text)'}}
            />
            <input
              type="text"
              name="payrollQuery"
              id="payrollQuery"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder={strings('Search Payroll')}
            />
            <div className={styles.searchFilterWrapper}>
              <Select
                optionList={searchFields}
                allItem
                onSelectValue={values => {
                  values.length > 0
                    ? setSearchField(values[0])
                    : setSearchField('all');
                }}
              />
            </div>
          </div>
          <span className={styles.numberRecord}>{`${total} ${strings(
            'Records'
          )}`}</span>
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.selectFilters}>
            <div className={styles.selectWrapper}>
              <Select
                fixedLabel={
                  <CalenderIcon
                    style={{
                      fill: 'var(--blue-accent)',
                    }}
                  />
                }
                defaultLabel={strings('Payroll Month')}
                optionList={payrollMonthOptions}
                multipleSelect
                allToggle
                allItem
                divider
                onSelectValue={values => setPayrollMonth(values)}
              />
              <Select
                fixedLabel={<LocationIcon />}
                defaultLabel={'BSC'}
                optionList={centres.map(item => ({
                  value: item.bsc,
                  content: item.bsc,
                }))}
                value={district.map(item => item)}
                multipleSelect
                searchable
                allToggle
                allItem
                divider
                onSelectValue={values => setDistrict(values)}
              />
              <Select
                fixedLabel={<TagIcon />}
                defaultLabel={strings('Status')}
                optionList={payrollStatusOptions}
                multipleSelect
                allToggle
                allItem
                onSelectValue={values => setStatus(values)}
              />
            </div>
            <div>{strings('Select Payslips to submit them')}</div>
          </div>
          <div className={styles.buttonWrapper}>
            {selectedRows.length > 0 && (
              <>
                <div>
                  {strings('%0 Payslips selected', selectedRows.length)}
                </div>
                <Button
                  type="danger"
                  label={strings('Submit')}
                  handleClick={() => submitModalRef.current.showModal()}
                />
              </>
            )}
            <Button
              type="outline"
              label={strings('Export')}
              handleClick={exportPayrolls}
            />
          </div>
          <Modal
            ref={submitModalRef}
            header={<h2>{strings('Submit Payslips?')}</h2>}
            body={
              <div>
                <div>
                  {strings('You are submitting')}
                  <b>{` ${selectedRows.length} ${strings('Payslips')}.`}</b>
                </div>
                <br />
                <div>{strings('This action cannot be undone.')}</div>
              </div>
            }
            footer={
              <>
                <Button
                  label={strings('Cancel')}
                  type="transparent"
                  handleClick={() => submitModalRef.current.hideModal()}
                />
                <Button
                  label={strings('Submit')}
                  type="danger"
                  handleClick={() => {
                    submitModalRef.current.hideModal();
                    submitPayrolls();
                  }}
                />
              </>
            }
          />
          <Modal
            ref={resultModalRef}
            showClose={false}
            body={
              <div>
                <div>{error}</div>
              </div>
            }
            footer={
              <>
                <Button
                  label={strings('Ok')}
                  type="danger"
                  handleClick={() => {
                    resultModalRef.current.hideModal();
                  }}
                />
              </>
            }
          />
        </div>
      </div>
      <Table
        columns={columns}
        data={data}
        rowClick
        rowSelect
        pageInput
        handleSelectRow={rows => setSelectedRows(rows)}
        hiddenColumns={hiddenColumns}
        manualQuery={searchValue}
        manualFilterSort={manualFilterSort}
        pageCountNumber={Math.ceil(total / _limit)}
        fetchData={fetchData}
      />
    </div>
  );
}
