import styles from './JobHistory.module.scss';
import {strings} from '../../../services/language';
import {Profile, Skill} from '../../../services/models';
import {Table, Select, PcfDatePicker} from '../../../components';
import {ReactComponent as SearchIcon} from '../../../assets/img/search.svg';
import {ReactComponent as WorkIcon} from '../../../assets/img/work.svg';
import {ReactComponent as LocationIcon} from '../../../assets/img/location.svg';
import {useJobHistoryLogic} from './logics';
import {districtOptions} from '../../../services/helpers';
import moment from 'moment';

type JobHistoryProps = {
  profile: Profile;
  skills: Skill[];
};

export default function JobHistory({profile, skills}: JobHistoryProps) {
  const {
    _limit,
    hiddenColumns,
    total,
    manualFilterSort,
    setRole,
    setDistrict,
    district,
    setStartDate,
    setEndDate,
    searchValue,
    setSearchValue,
    setSearchField,
    searchFilterList,
    centres,
    skillOptions,
    columns,
    data,
    fetchData,
  } = useJobHistoryLogic(profile.id, skills);

  return (
    <div className={styles.container}>
      <h3>{`${strings('Experience')}: ${moment().diff(
        profile.joinedAt ? moment(profile.joinedAt) : moment(),
        'year'
      )} ${strings('Years').toLowerCase()}, ${strings(
        '%0 past jobs',
        total || 0
      )}`}</h3>
      <div className={styles.filtersContainer}>
        <div className={styles.searchWrapper}>
          <div className={styles.searchInput}>
            <SearchIcon
              className={styles.searchIcon}
              style={{fill: 'var(--grey-text)'}}
            />
            <input
              type="text"
              name="searchQuery"
              id="searchQuery"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder={strings('Search Job History')}
            />
            <div className={styles.searchFilterWrapper}>
              <Select
                optionList={searchFilterList}
                allItem
                onSelectValue={values => {
                  values.length > 0
                    ? setSearchField(values[0])
                    : setSearchField('all');
                }}
              />
            </div>
          </div>
          <span className={styles.numberRecord}>{`${total} ${strings(
            'Records'
          )}`}</span>
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.selectFilters}>
            <Select
              fixedLabel={
                <WorkIcon
                  style={{
                    stroke: 'var(--blue-accent)',
                    width: '16px',
                    height: '16px',
                  }}
                />
              }
              defaultLabel={strings('Roles')}
              optionList={skillOptions}
              multipleSelect
              allToggle
              allItem
              onSelectValue={values => setRole(values)}
            />
            <PcfDatePicker
              placeholder={strings('Schedule')}
              onSelectDate={value => {
                setStartDate(value.from || '');
                setEndDate(value.to || '');
              }}
            />
            <Select
              fixedLabel={<LocationIcon />}
              defaultLabel={'BSC'}
              optionList={centres.map(item => ({
                value: item.bsc,
                content: item.bsc,
              }))}
              value={district.map(item => item)}
              multipleSelect
              searchable
              allToggle
              allItem
              divider
              onSelectValue={values => setDistrict(values)}
            />
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        data={data}
        rowClick
        hiddenColumns={hiddenColumns}
        manualQuery={searchValue}
        manualFilterSort={manualFilterSort}
        pageCountNumber={Math.ceil(total / _limit)}
        fetchData={fetchData}
      />
    </div>
  );
}
