import {
  exportPayrollsAPI,
  getPayrollsAPI,
  submitPayrollsAPI,
  useAPI,
  useResult,
} from '../../../services/api';
import {useEffect} from 'react';
import {useLoading, useSyncCentres} from '../../../services/hooks';
import {strings} from '../../../services/language';
import {Badge} from '../../../components';
import styles from './PayrollRecords.module.scss';
import {useMemo, useRef, useState} from 'react';
import {Cell, Column, Row, TableInstance} from 'react-table';
import {ReactComponent as ChevronIcon} from '../../../assets/img/chevron.svg';
import {useHistory} from 'react-router-dom';
import {toRoute} from '../../../services/router';
import {
  Centre,
  Payroll,
  PayrollStatus,
  Profile,
} from '../../../services/models';
import {
  payrollStatusOptions,
  createPayrollMonthOptions,
  districtOptions,
} from '../../../services/helpers';
import moment from 'moment';
import {downloadCsv, formatISOString} from '../../../services/utils';

const _limit = 8;

export function usePayrollsLogic() {
  const [total, setTotal] = useState(0);
  const [manualFilterSort, setManualFilterSort] = useState<any>(undefined);
  const [selectedRows, setSelectedRows] = useState<Row<object>[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const [district, setDistrict] = useState<string[]>([]);
  const [payrollMonth, setPayrollMonth] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchField, setSearchField] = useState('all');
  const [payrollList, setPayrollList] = useState<
    (Payroll & {profile: Profile})[]
  >([]);
  const {centres} = useSyncCentres();
  const [error, setError] = useState('');

  const history = useHistory();
  const submitModalRef = useRef<any>(null);
  const resultModalRef = useRef<any>(null);

  const searchFields = useMemo(
    () => [
      {
        content: strings('Centre'),
        value: 'centreName',
      },
      {
        content: strings('Relief Staff'),
        value: 'profileName',
      },
      {
        content: strings('Employee Code'),
        value: 'profileEmployeeCode',
      },
    ],
    []
  );

  const payrollMonthOptions = useMemo(() => createPayrollMonthOptions(2), []);

  const hiddenColumns = ['selectable', 'payrollId'];

  useEffect(() => {
    setManualFilterSort([]);
  }, [status, district, searchField, payrollMonth]);

  const payrollsApi = useAPI(getPayrollsAPI);
  const submitPayrollsApi = useAPI(submitPayrollsAPI);
  const exportPayrollsApi = useAPI(exportPayrollsAPI);

  function exportPayrolls() {
    exportPayrollsApi.request({
      _start: 0,
      _limit: _limit,
      _sort: 'id:DESC',
      searchField,
      ...(searchValue && {query: searchValue}),
      ...(status.length > 0 && {statuses: status}),
      ...(district.length > 0 && {centreBSC: district.join(',')}),
      ...(payrollMonth.length > 0 && {payrollPeriods: payrollMonth}),
      export_csv: true,
    });
  }

  useResult(exportPayrollsApi.data, data => {
    if (data.content) {
      downloadCsv(data.filename, data.content);
    }
  });

  function fetchData(pageIndex: number) {
    payrollsApi.request({
      _start: pageIndex * _limit,
      _limit: _limit,
      _sort: 'id:DESC',
      searchField,
      ...(searchValue && {query: searchValue}),
      ...(status.length > 0 && {statuses: status}),
      ...(district.length > 0 && {centreBSC: district.join(',')}),
      ...(payrollMonth.length > 0 && {payrollPeriods: payrollMonth}),
    });
  }

  useResult(payrollsApi.data, data => {
    if (data.total !== total) {
      setTotal(data.total);
    }
    if (data.list) {
      setPayrollList(data.list);
    }
  });

  function submitPayrolls() {
    if (selectedRows.length > 0) {
      submitPayrollsApi.request({
        payrollIds: selectedRows.map(row => row.values.payrollId),
      });
    }
  }

  useEffect(() => {
    if (submitPayrollsApi.error && !submitPayrollsApi.loading) {
      setError(submitPayrollsApi.error);
      resultModalRef.current.showModal();
    }
  }, [submitPayrollsApi.loading]);

  useEffect(() => {
    if (exportPayrollsApi.error && !exportPayrollsApi.loading) {
      setError(strings('Please select only 1 payroll period'));
      resultModalRef.current.showModal();
    }
  }, [exportPayrollsApi.loading]);

  useResult(submitPayrollsApi.data, data => {
    if (data.list.length > 0) {
      const submitedPayrollIds = data.list.map(p => p.id);
      const updatedPayrolls: (Payroll & {profile: Profile})[] = payrollList.map(
        p => {
          if (submitedPayrollIds.includes(p.id)) {
            return {...p, status: 'processing'};
          }
          return p;
        }
      );
      setPayrollList(updatedPayrolls);
    }
  });

  const columns: Column[] = useMemo(() => createColumns(), []);
  const data: PayrollData[] = useMemo(
    () => createData(history, payrollList, centres),
    [payrollList, centres]
  );

  useLoading(
    payrollsApi.loading ||
      submitPayrollsApi.loading ||
      exportPayrollsApi.loading
  );

  return {
    _limit,
    columns,
    data,
    hiddenColumns,
    total,
    district,
    centres,
    manualFilterSort,
    selectedRows,
    setSelectedRows,
    setStatus,
    setDistrict,
    setPayrollMonth,
    setSearchField,
    searchValue,
    setSearchValue,
    submitModalRef,
    searchFields,
    payrollMonthOptions,
    payrollStatusOptions,
    districtOptions,
    fetchData,
    submitPayrolls,
    resultModalRef,
    submitPayrollsApi,
    exportPayrolls,
    error,
  };
}

interface PayrollData {
  payslipId: JSX.Element;
  staff: JSX.Element;
  centre: string;
  district: string;
  datesPaid: string;
  amount: string;
  status: PayrollStatus;
  dateSubmitted: string;
  datePaid: string;

  selectable: boolean;
  payrollId: number;

  button: JSX.Element;
}

function createColumns() {
  return [
    {
      Header: strings('Payroll Month/ Payslip ID'),
      accessor: 'payslipId',
    },
    {
      Header: `${strings('Staff Name')}/ ${strings('Employee Code')}`,
      accessor: 'staff',
    },
    {
      Header: strings('Centre'),
      accessor: 'centre',
    },
    {
      Header: 'BSC',
      accessor: 'bsc',
    },
    {
      Header: strings('Dates Paid'),
      accessor: 'datesPaid',
    },
    {
      Header: strings('Accumulated Amount'),
      accessor: 'amount',
    },
    {
      Header: strings('Status'),
      accessor: 'status',
      Cell: (props: TableInstance & Cell) => (
        <Badge type={'payrollStatus'} status={props.value} />
      ),
      filter: 'selectOptions',
    },
    {
      Header: strings('Date Submitted'),
      accessor: 'dateSubmitted',
    },
    {
      Header: strings('Date Paid'),
      accessor: 'datePaid',
    },
    {
      Header: '',
      accessor: 'selectable',
    },
    {
      Header: '',
      accessor: 'payrollId',
    },
    {
      Header: '',
      accessor: 'button',
    },
  ];
}

function createData(
  history: any,
  payrolls: (Payroll & {profile: Profile})[],
  centres: Centre[]
): PayrollData[] {
  return payrolls.map(payroll => {
    const centre = centres.filter(centre => centre.id === payroll.centreId)[0];

    return {
      payslipId: (
        <div className={styles.columnsCell}>
          <div>{moment(payroll.period, 'YYYYMM').format('MMM YYYY')}</div>
          <div className={styles.greyText}>{payroll.payslipId}</div>
        </div>
      ),
      staff: (
        <div className={styles.columnsCell}>
          <div>{payroll.profile.name}</div>
          <div className={styles.greyText}>
            {payroll.profile.employeeCode || ''}
          </div>
        </div>
      ),
      centre: centre ? centre.name : '',
      bsc: centre ? centre.bsc : '-',
      district: centre ? centre.districtGroup : '',
      datesPaid: `${formatISOString(
        payroll.periodStart,
        'DD/MM/YY'
      )}-${formatISOString(payroll.periodEnd, 'DD/MM/YY')}`,
      amount: `$${payroll.amount}`,
      status: payroll.status,
      dateSubmitted: payroll.sentAt
        ? formatISOString(payroll.sentAt, 'DD/MM/YY')
        : '-',
      datePaid: payroll.paidAt
        ? formatISOString(payroll.paidAt, 'DD/MM/YY')
        : '-',
      selectable: payroll.status === 'pending',
      payrollId: payroll.id,
      button: (
        <div
          className={styles.buttonCell}
          onClick={e => {
            if (e) {
              e.stopPropagation();
            }
            history.push(toRoute('/timesheets', {payrollId: payroll.id}));
          }}
        >
          <ChevronIcon
            style={{stroke: 'var(--blue-accent)', transform: 'rotate(-90deg)'}}
          />
        </div>
      ),
    };
  });
}
